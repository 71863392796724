export const TRANSLATIONS_FR = {
    common: {
        at: 'à',
        noResults: '0 résultats trouvé',
        back: 'Retour',
        modifyInfo: 'Modifier',
        delete: 'Supprimer',
        cancel: 'Annuler',
        save: 'Enregistrer',
        confirm: 'Confirmer',
        now: 'Maintenant',
        resendEmail: 'Renvoyer l’email explicatif',
        retrieveKey: 'Récupérer la clé',
        cancelAndKey: 'Annuler et récupérer la clé',
        downloadInvoice: 'Télécharger la facture',
        previous: 'Précédent',
        next: 'Suivant',
        validate: 'Valider',
        hello: 'Bonjour',
        openLocker: 'Ouvrir le casier',
        lockerIsOpened: 'Le casier est ouvert',
        openPortal: 'Ouvrir la barrière',
        portalIsOpened: 'La barrière est ouverte',
        unavailable: 'Indisponible',
        noReturn: 'Pas de retour',
        noDeparture: 'Pas de départ',
        all: 'Tous',
        home: 'Accueil',
        noObjects: 'Aucun objet',
        generalInformations: 'Informations générales',
        details: 'Détails',
        logout: 'Se déconnecter',
        vehicle: 'Véhicule',
        dropOffKey: 'Déposer la clé',
        registration: 'Inscription',
        search: 'Recherche',
        seeMore: 'Voir plus',
        summary: 'Récapitulatif',
        notSpecified: 'Non renseigné',
        add: 'Ajouter',
        yes: 'Oui',
        no: 'Non',
    },

    header: {
        exchanges: 'Échanges',
        reservations: 'Réservations',
        vehicles: 'Véhicules',
        clients: 'Clients',
    },

    language: {
        french: 'Français',
        english: 'Anglais',
        italian: 'Italien',
    },

    informations: {
        clientID: 'ID du client',
        Lastname: 'Nom',
        Firstname: 'Prénom',
        Address: 'Adresse complète',
        PhoneNumber: 'Téléphone',
        Email: 'Email',
        brand: 'Marque',
        model: 'Modèle',
        registrationNumber: 'Immatriculation',
        vehicleId: 'ID du véhicule',
        reservationId: 'ID de la réservation',
        status: 'Statut',
        startDate: 'Date de départ',
        endDate: 'Date de retour',
        totalReservations: 'Total de réservation',
        waitingVehicleAssociation: 'En attente de dépôt de clé',
        waitingVehiclePickup: 'En attente de retour',
        waitingVehicleDropOff: 'En attente de départ',
        closed: 'Terminé',
        canceled: 'Annulé',
        companyID: 'ID de l’entreprise',
        companyName: 'Nom de la société',
        totalAgency: 'Nombre d’agence',
        totalEmployees: 'Nombre d’employé',
        garageID: 'ID du garage',
        createdAt: 'Crée le',
        job: 'Poste',
        registrationDate: 'Date d’inscription',
        lastModified: 'Dernière modification le',
        PreferedLanguage: 'Langue préférée',
        roles: 'Rôles',
    },

    feedback: {
        confirmAction: 'Votre demande a bien été prise en compte.',
        planDropOff: 'Le rendez-vous de dépose a bien été créé.',
        planReturn: 'Votre demande a bien été crée.',
        lockerOpened: 'Le casier a bien été ouvert.',
        redirectPayment: 'Vous allez être redirigé vers la page de paiement...',
        objectCreated: "L'objet a bien été créé.",
        error: 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
        clientExist: 'This email adresse already exist.',
        locker: {
            open: 'Êtes-vous sûr de vraiment vouloir ouvrir le Locker ?',
            description: 'Vous êtes sur le point d’ouvrir le Locker, cette action est irréversible',
        },
        cancelDropOff: {
            title: 'Êtes-vous sûr de vraiment vouloir annuler ce rendez-vous ?',
            description: 'Vous êtes sur le point annuler le rendez-vous, cette action est irréversible',
        },
        cancelRetrieve: {
            title: 'Êtes-vous sûr de vraiment vouloir annuler ce rendez-vous ?',
            description: 'Vous êtes sur le point annuler le rendez-vous, cette action est irréversible',
        },
        cancelReservation: {
            title: 'Êtes-vous sûr de vraiment vouloir annuler cette reservation ?',
            description: 'Vous êtes sur le point annuler la reservation, cette action est irréversible',
        },
        client: {
            modified: 'Le client a bien été modifié.',
            created: 'Le client a bien été créé.',
            deleted: "L'utilisateur {{ name }} à bien été supprimé.",
            confirmDelete: 'Êtes-vous sûr de vraiment vouloir supprimer {{name}} ?',
        },
        vehicle: {
            created: 'Le véhicule à bien été crée.',
            deleted: 'Le véhicule {{name}} à bien été supprimé.',
            confirmDelete: 'Êtes-vous sûr de vraiment vouloir supprimer le véhicule {{name}} ?',
            descriptionDelete:
                'Vous êtes sur le point de supprimer le véhicule, attention cette action est irréversible',
            pickUp: 'Vous êtes sur le point de récupérer la clé du véhicule. Voulez-vous continuer ?',
        },
        agency: {
            created: "L'agence a bien été crée.",
            deleted: "L'agence {{ name }} à bien été supprimé.",
            confirmDelete: "Êtes-vous sûr de vraiment vouloir supprimer l'agence {{name}} ?",
            descriptionDelete:
                'Vous êtes sur le point de supprimer cette agence, attention cette action est irréversible',
            modified: "L'agence {{ name }} à bien été modifié.",
        },
        employee: {
            created: "L'employé à bien été crée.",
            deleted: "L'employé {{ name }} à bien été supprimé.",
            confirmDelete: "Êtes-vous sûr de vraiment vouloir supprimer l'employé {{name}} ?",
            descriptionDelete:
                'Vous êtes sur le point de supprimer cette employé, attention cette action est irréversible',
            modified: "L'employé {{ name }} à bien été modifié.",
        },
    },

    error: {
        email: 'Veuillez rentrez un email valide',
        password: 'Veuillez rentrez un mot de passe valide',
        phone: 'Veuillez rentrez un numéro de téléphone valide',
    },

    form: {
        label: {
            password: 'Mot de passe',
            Firstname: 'Prénom',
            Lastname: 'Nom',
            Name: 'Nom',
            Email: 'Email',
            PhoneNumber: 'Téléphone',
            Address: 'Adresse',
            PersonalItemName: "Nom de l'object",
            fullName: 'Nom complet ou email',
            selectObject: 'Historique des objets',
            agency: 'Agence',
            locker: 'Locker',
            dropOff: 'Date de dépôt',
            pickUp: 'Date de retrait',
            registration: 'Immatriculation',
            brand: 'Marque',
            model: 'Modèle',
            rentalId: 'N° de réservation',
            fullAddress: 'Adresse complète',
            Post: 'Poste',
            searchVehicle: 'Recherche du véhicule',
            availableVehicles: 'Disponible',
            PreferedLanguage: 'Langue préférée',
            Roles: 'Rôles',
        },
        placeholder: {
            email: 'Entrez votre email',
            password: 'Entrez votre mot de passe',
            confirmPassword: 'Confirmer votre mot de passe',
            search: 'Rechercher un client',
            object: "Nom de l'objet",
            selectObject: 'Sélectionner un objet',
            Firstname: 'Entrez le prénom du client',
            Lastname: 'Entrez son nom',
            Email: 'Entrez son email',
            PhoneNumber: 'Entrez son numéro de téléphone',
            Address: 'Entrez son adresse',
            PersonalItemName: 'Entrer le nom du nouvel objet',
            dropOffDate: 'Sélectionner la date de dépôt',
            pickUpDate: 'Sélectionner la date de retrait',
            price: '€ 1,000.00',
            fullName: 'Entrer le nom complet ou l’email du client',
            agency: 'Choississez votre agence',
            locker: 'Choississez un Locker',
            dropOff: 'Choississez la date de dépôt',
            registration: "Entrez l'immatriculation du véhicule",
            brand: 'Entrez la marque du véhicule',
            model: 'Entrez le modèle du véhicule',
            rentalId: 'Entrez le numéro de la réservation',
            agencyName: 'Entrez le nom de l’agence',
            fullAddress: 'Entrez son adresse complète',
            Post: 'Entrez son poste',
            searchVehicle: 'Nom du véhicule',
            Roles: 'Sélectionner un ou plusieurs rôles',
        },
        forgetPassword: 'Mot de passe oublié ?',
        skipStep: 'Passer cette étape',
        messages: {
            required: 'Ce champ est obligatoire.',
            pattern: {
                mismatch: "Le champ n'est pas valide.",
            },
        },
    },

    login: {
        title: 'Bienvenue',
        subtitle: 'Connectez-vous à votre compte',
        submit: 'Se connecter',
        noAccount: 'Vous n’avez pas encore de compte ?',
        registerLink: "S'inscrire",
    },

    register: {
        title: 'Créer un compte',
        submit: "S'inscrire",
        loginText: 'Vous avez déja un compte ?',
        loginLink: 'Se connecter',
    },

    resetPassword: {
        title: 'Réinitialisation du mot de passe',
        subtitle:
            "Saisissez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
        submit: 'Continuer',
        loginLink: 'Revenir à la page de connexion',
    },

    clientPage: {
        timeline: 'Timeline',
        client: 'Fiche client',
        reservations: 'Réservations',
        exchanges: 'Échanges',
        invoices: 'Factures',
        object: 'Objet',
        addAction: 'Ajouter une action',
        delete: 'Supprimer le client',
        descriptionDelete: 'Vous êtes sur le point de supprimer ce client, attention cette action est irréversible',
        historyReservations: 'Historique des réservations',
    },

    reservationPage: {
        timeline: 'Timeline',
        vehicle: 'Véhicule',
        clientLink: 'Voir la fiche client',
        vehicleLink: 'Voir la fiche véhicule',
    },

    vehiclePage: {
        timeline: 'Timeline',
        vehicle: 'Fiche véhicule',
        reservation: 'Réservation',
        damages: 'Dommages',
        delete: 'Supprimer le véhicule',
    },

    clientModal: {
        title: 'Ajouter un nouveau client',
        description: 'Veuillez renseigner les informations du client',
    },

    objectModal: {
        title: 'Rattacher un objet au client (optionnel)',
        description: 'Veuillez renseigner un objet auquel sera rattaché ce client',
        selectTitle: 'Renseigner l’objet concernant l’échange',
        selectDescription: 'Veuillez sélectionner ou ajouter l’objet concerné par votre demande',
        selectPlaceholder: 'Clé de voiture',
        addObject: 'Ajouter un nouvel objet',
        backHistory: 'Revenir à l’historique des objets',
    },

    vehiclesModal: {
        title: '(1/3) Assigner un véhicule',
        description: 'Veuillez renseigner l’immatriculation, la marque ou le modèle du véhicule recherché',
    },

    createVehicleModal: {
        title: 'Créer un véhicule',
    },

    selectClientModal: {
        title: '(2/3) Assigner la réservation',
        description: 'Veuillez renseigner le nom ou l’adresse email du client auquel sera assigné la réservation',
        newClient: 'Ajouter un nouveau client',
        existingClient: 'Client déjà existant',
    },

    reservationsDateModal: {
        title: '(3/3) Choississez vos dates',
        description: 'Veuillez renseigner la date de départ et/ou la date de retour',
        pickUpDate: 'Départ',
        pickUpPlaceholder: 'Choisissez la date de départ',
        dropOffDate: 'Retour',
        dropOffPlaceholder: 'Choisissez la date de retour',
    },

    dropOffModal: {
        title: 'Locker / Date de dépôt',
        description:
            'Veuillez sélectionner le Locker dans lequel sera déposé l’objet en question ainsi que la date prévu pour le dépôt',
    },

    invoiceModal: {
        title: 'Importer la facture',
        description: 'Veuillez importer la facture afin que nous puissions l’envoyé au client',
        import: 'Cliquer pour importer',
        drag: 'ou glisser-déposer',
        restriction: 'PDF (max. 800x400px)',
    },

    agencyModal: {
        title: 'Ajouter une agence',
        description: 'Veuillez renseigner les informations de l’agence',
        modifyInfo: 'Modifier une agence',
    },

    employeeModal: {
        title: 'Ajouter un employé',
        description: 'Veuillez renseigner les informations de l’employé',
        modifyInfo: 'Modifier un employé',
    },

    events: {
        userDropOffStep: 'En attente de la dépose prévue par {{name}} le {{date}}',
        userDropOffFinishedStep: 'Déposé par {{name}}',
        garagePickUpStep: 'En attente de récupération par le garage...',
        pickUpFinishedStep: 'Récupéré par {{name}}',
        garageDropOffStep: 'En attente de la dépose par le garage...',
        garageDropOffFinishedStep: 'Déposé par {{name}}',
        garageDropOffCanceledStep: 'Annulé par {{name}}',
        userDropOffPlanedStep: 'Planifié par {{name}}',
        pickUpCanceledStep: 'Annulé par {{name}}',
        paidGarageReturnItemPlanedStep: 'Planifié par {{name}}',
        freeGarageReturnItemPlanedStep: 'Planifié par {{name}}',
        userPaymentStep: 'En attente de paiement...',
        userPaymentDoneStep: 'Paiement effectué !',
        userPickUpStep: 'En attente de récupération par le client...',
        vehicle: {
            pickUpVehicleAppointmentMadeStep: 'Départ planifié pour la réservation n°{{id}} de {{name}}',
            appointmentVehicleAssociationStep: 'En attente du dépôt de la clé du véhicule par un agent...',
            appointmentVehicleAssociatedStep: 'Clé du véhicule déposée',
            appointmentVehiclePickUpStep: 'En attente de départ de {{name}}...',
            appointmentVehiclePickedUpStep: 'Départ effectué',
            dropOffVehicleAppointmentMadeStep: 'Retour planifié pour la réservation n°{{id}} de {{name}}',
            appointmentVehiclePickedUpStepCanceled: 'Départ annulé de la réservation n°{{id}} de {{name}}',
            appointmentVehicleDroppedOffStepCanceled: 'Retour annulée de la réservation n°{{id}} de {{name}}',
            appointmentVehicleDropOffStep: 'En attente de retour de {{name}}...',
            appointmentVehicleDroppedOffStep: 'Retour effectué par {{name}}',
        },
    },

    newAction: {
        creation: {
            title: 'Liste des objets',
        },
        list: {
            title: 'Dites-nous en plus sur l’action',
            description: 'Veuillez sélectionner l’objet concerné',
            button: 'Ajouter un nouvel objet',
        },
        type: {
            title: 'Dites-nous en plus sur l’action',
            description: 'Veuillez sélectionner l’action souhaitée parmi les choix ci-dessous',
            dropOff: 'Prendre un rendez-vous de dépose',
            planFree: 'Restituer la clé sans paiement',
            planPaid: 'Restituer la clé avec paiement',
            reservation: 'Créer une réservation',
        },
        date: {
            title: 'Indiquer la date et le lieu de dépôt',
            inDoor: 'Indoor',
            outDoor: 'Outdoor',
        },
        invoice: {
            pickUp: 'Indiquer la date et le lieu de récupération',
            button: 'Importer la facture',
            description: 'Veuillez importer la facture afin que nous puissions l’envoyer au client',
            price: 'Montant',
        },
    },

    404: {
        title: 'ERREUR 404',
        subtitle: "Désolé, cette page n'est pas disponible",
        text: "Cette page est introuvable... Vous êtes à la recherche d'une page ?",
    },

    dropOffPage: {
        hero: {
            subHeading: 'Content de vous voir !',
            description:
                'Notre Service Après-Vente ByMyCar est équipé d’une consigne à clés digitale et sécurisée vous permettant de ne plus avoir à attendre qu’un conseiller soit disponible pour déposer la clé de votre véhicule.',
            openPortal: 'Pour ouvrir la barrière et accéder au parking, veuillez cliquer sur le bouton suivant',
        },
        section1: {
            subHeading: 'Très important',
            title: 'Quelques instructions',
            text: {
                1: "1. Rendez-vous près de la consigne à clés digitale située près de l'accueil.",
                2: '2. Ensuite cliquez sur le bouton ci-dessous.',
                3: "3. Appuyez sur le bouton poussoir de la consigne à clés lorsqu'il se mettra à clignoter.",
                4: '4. Déposez la clé de votre véhicule dans le casier libéré et refermez-le.',
                5: "Nos équipes seront informées en temps réel de l'arrivée de votre véhicule.",
            },
        },
        section2: {
            subHeading: 'N’oubliez pas',
            title: 'À déposer avant le',
            text: {
                1: 'Horaires disponibles',
                2: 'Lundi au Samedi : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Oui mais où ça ?',
            title: 'Lieu de dépôt',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Téléphone',
                schedules: 'Lundi au Samedi : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'Nous vous tiendrons informé par email dès que la réception de votre véhicule sera effectuée.',
                2: 'Excellente journée.',
                3: 'Votre partenaire ByMyCar',
            },
        },
    },

    rentalDropOffPage: {
        hero: {
            subHeading: 'Content de vous voir !',
            description:
                'Notre agence est équipée d’une consigne à clés digitale et sécurisée vous permettant de ne plus avoir à attendre qu’un conseiller soit disponible pour restituer la clé de votre véhicule de location.',
            openPortal: 'Pour ouvrir la barrière et accéder au parking, veuillez cliquer sur le bouton suivant',
        },
        section1: {
            subHeading: 'Très important',
            title: 'Quelques instructions',
            text: {
                1: "1. Rendez-vous près de la consigne à clés digitale située près de l'accueil.",
                2: '2. Ensuite cliquez sur le bouton ci-dessous.',
                3: "3. Appuyez sur le bouton poussoir de la consigne à clés lorsqu'il se mettra à clignoter.",
                4: '4. Déposez la clé de votre véhicule de location dans le casier libéré et refermez-le.',
                5: 'Nos équipes seront informées en temps réel de la restitution de votre véhicule de location.',
            },
        },
        section2: {
            subHeading: 'N’oubliez pas',
            title: 'À déposer avant le',
            text: {
                1: 'Horaires disponibles',
                2: 'Lundi au Samedi : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Oui mais où ça ?',
            title: 'Lieu de dépôt',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Téléphone',
                schedules: 'Lundi au Samedi : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'Nous vous tiendrons informé par email dès que la réception de votre véhicule sera effectuée.',
                2: 'Excellente journée.',
                3: 'Votre partenaire ByMyCar',
            },
        },
    },

    paymentFailed: {
        title: 'Paiement échoué !',
        text: "Nous vous invitons à renouveler l'opération à l'aide d'un autre moyen de paiement. <br /> <br /> Vous pouvez fermer cette fenêtre.",
        button: 'Retourner à l’accueil',
    },

    paymentSuccess: {
        subHeading: 'Félicitation',
        title: 'Paiement réussi !',
        text: {
            1: 'Vous recevrez dans quelques instants, par email, les instructions de récupération de votre véhicule. <br /> <br /> Vous pouvez fermer cette fenêtre.',
            2: 'Nous vous remercions pour votre confiance !<br /> <br /> Votre partenaire ByMyCar',
        },
        button: 'Retourner à l’accueil',
    },

    paymentPage: {
        subHeading: 'Paiement en attente',
        text: ' Votre véhicule est prêt !<br /> <br /> Afin de gagner du temps lors de sa récupération, nous vous invitons à régler le montant de votre facture directement via notre service en ligne sécurisé.',
        button: 'Procéder au paiement',
    },

    pickUpPage: {
        hero: {
            subHeading: 'Content de vous voir !',
            description:
                'Notre Service Après-Vente ByMyCar est équipé d’une consigne à clés digitale et sécurisée vous permettant de ne plus avoir à attendre qu’un conseiller soit disponible pour déposer la clé de votre véhicule.',
            openPortal: 'Pour ouvrir la barrière et accéder au parking, veuillez cliquer sur le bouton suivant',
        },
        section1: {
            subHeading: 'Très important',
            title: 'Quelques instructions',
            text: {
                1: "1. Rendez-vous près de la consigne à clés digitale située près de l'accueil.",
                2: '2. Ensuite cliquez sur le bouton ci-dessous.',
                3: " 3. Appuyez sur le bouton poussoir de la consigne à clés lorsqu'il se mettra à clignoter.",
                4: '4. Déposez la clé de votre véhicule dans le casier libéré et refermez-le.',
                5: '5. Récupérez votre véhicule directement sur le parking.',
                6: "Nos équipes seront informées en temps réel de l'arrivée de votre véhicule.",
            },
        },
        section2: {
            subHeading: 'N’oubliez pas',
            title: 'À déposer avant le',
            text: {
                1: 'Horaires disponibles',
                2: 'Lundi au Samedi : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Oui mais où ça ?',
            title: 'Lieu de dépôt',
            text: {
                1: "Relais de l'Arbalète, 17 Route de Corbeil",
            },
            phone: {
                title: 'Téléphone',
                schedules: 'Lundi au Samedi : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: "Nous vous tiendrons informé par email dès que l'intervention sur votre véhicule sera terminée.",
                2: 'Excellente journée.',
                3: 'Votre partenaire ByMyCar',
            },
        },
    },

    rentalPickUpPage: {
        hero: {
            subHeading: 'Content de vous voir !',
            description:
                'Pour récupérer la clé du véhicule de location, rendez-vous près de la consigne à clés digitale et cliquez sur ce bouton.',
            openPortal: 'Pour ouvrir la barrière et accéder au parking, veuillez cliquer sur le bouton suivant',
        },
        section1: {
            subHeading: 'Très important',
            title: 'Quelques instructions',
            text: {
                1: "1. Rendez-vous près de la consigne à clés digitale située près de l'accueil.",
                2: '2. Ensuite cliquez sur le bouton ci-dessus.',
                3: "3. Appuyez sur le bouton poussoir de la consigne à clés lorsqu'il se mettra à clignoter.",
                4: '4. Récupérez la clé de votre véhicule dans le casier libéré et refermez-le.',
                5: '5. Récupérez le véhicule directement sur le parking.',
                6: 'Nos équipes seront informées en temps réel de votre départ.',
            },
        },
        section2: {
            subHeading: 'N’oubliez pas',
            title: 'Date de début le',
            text: {
                1: 'Horaires disponibles',
                2: 'Lundi au Dimanche : 00:00 - 23:59',
            },
        },
        section3: {
            subHeading: 'Oui mais où ça ?',
            title: 'Lieu de retrait',
            company: 'Avis Location Voiture - Toulon',
            text: {
                1: 'Gare de Toulon',
                2: 'Gare Sncf Place De L Europe Retours Parking Effia -1, 83000 Toulon',
            },
            phone: {
                title: 'Téléphone',
                schedules: 'Lundi au Dimanche : 00:00 - 23:59',
                phoneNumber: '0 820 61 16 45',
            },
        },
        footer: {
            text: {
                1: 'Excellente journée.',
                2: 'Avis Location Voiture - Toulon',
            },
        },
    },

    parametersPage: {
        title: 'Paramètres',
        subtitle: 'Gérer tous vos paramètres',
        addLocker: 'Ajouter un locker',
        tabs: {
            company: 'Entreprise',
            agency: 'Agences',
            locker: 'Locker',
            stripe: 'Stripe',
            interface: 'Interface',
        },
        stripe: {
            title: 'Stripe',
            subtitle: 'Gérez toutes vos interactions avec votre compte Stripe',
        },
        interface: {
            title: 'Mon interface',
            subtitle: 'Personnalisez votre interface à l’image de votre entreprise',
            logo: 'Logo',
            primaryColor: 'Couleur principale',
        },
    },

    agencyPage: {
        addEmployee: 'Ajouter un employé',
        delete: 'Supprimer l’agence',
        addAgency: 'Ajouter une agence',
    },

    employeePage: {
        delete: 'Supprimer l’employé',
    },

    listVehicles: {
        title: 'Véhicules',
        subtitle: 'Gérer tous vos véhicules',
        add: 'Ajouter un véhicule',
        deleteVehicle: 'Supprimer le véhicule',
    },

    listExchanges: {
        title: 'Échanges',
        subtitle: 'Gérer tous vos échanges',
        add: 'Ajouter un échange',
    },

    listClients: {
        title: 'Clients',
        subtitle: 'Gérer tous vos clients',
        add: 'Ajouter un client',
    },

    listReservations: {
        title: 'Réservations',
        subtitle: 'Gérer toutes vos réservations',
        add: 'Ajouter une réservation',
        clientLink: 'Voir la fiche client',
        vehicleLink: 'Voir la fiche véhicule',
    },

    gridExchange: {
        searchPlaceholder: 'Recherche d’un échange',
        columns: {
            dropOffUser: 'Déposé par',
            dropOffDate: 'Date de dépôt',
            pickUpUser: 'Pour',
            pickUpDate: 'Date de récupération',
            depositDate: 'Date de dépôt',
            status: 'Statut',
        },
        tabs: {
            waiting: 'En attente de dépôt',
            delivered: 'Déposé',
            retrieved: 'Récupéré',
            canceled: 'Annulé',
        },
        tags: {
            waiting: 'En attente de dépôt',
            delivered: 'Déposé',
            retrieved: 'Récupéré',
            canceled: 'Annulé',
        },
    },

    gridReservations: {
        searchPlaceholder: 'Recherche d’une réservation',
        columns: {
            reservation: 'Réservation',
            client: 'Client',
            registrationNumber: 'Immatriculation',
            brandModel: 'Marque/Modèle',
            startDate: 'Date de départ',
            returnDate: 'Date de retour',
            status: 'Statut',
        },
        tabs: {
            waitingAssociation: 'En attente de dépôt',
            waitingPickup: 'En attente de départ',
            waitingDropOff: 'En attente de retour',
            closed: 'Terminé',
            canceled: 'Annulé',
        },
        tags: {
            waitingVehicleAssociation: 'En attente de dépôt de clé',
            waitingVehiclePickup: 'En attente de départ',
            waitingVehicleDropOff: 'En attente de retour',
            closed: 'Terminé',
            canceled: 'Annulé',
        },
    },

    gridVehicles: {
        columns: {
            id: 'ID',
            registrationNumber: 'Immatriculation',
            brandModel: 'Marque/Modèle',
            status: 'Statut',
        },
        tabs: {
            available: 'Disponible',
            booked: 'Réservé',
            unavailable: 'Indisponible',
        },
        tags: {
            available: 'Disponible',
            booked: 'Réservé',
            unavailable: 'Indisponible',
        },
    },

    gridClients: {
        columns: {
            fullName: 'Nom complet',
            email: 'Email',
            phone: 'Numéro de téléphone',
            id: 'ID',
            registrationNumber: 'Immatriculation',
            startDate: 'Date de départ',
            returnDate: 'Date de retour',
            status: 'Statut',
        },
    },

    gridAgencies: {
        title: 'Agences',
        summary: 'Récapitulatif des agences',
        columns: {
            name: 'Nom',
            address: 'Adresse',
            phoneNumber: 'Téléphone',
        },
    },

    gridEmployees: {
        title: 'Employés',
        summary: 'Récapitualif',
        columns: {
            name: 'Nom complet',
            job: 'Poste',
            email: 'Email',
            phoneNumber: 'Téléphone',
        },
        tabs: {
            employee: 'Employés de l’agence',
        },
    },

    gridLocker: {
        title: 'Locker',
        columns: {
            name: 'Nom',
            agency: 'Agence',
            address: 'Adresse',
            openingHours: 'Horaires d’accès',
        },
    },

    timeline: {
        title: 'Timeline',
        noActions: {
            vehicles: 'Veuillez rajouter une action pour cette timeline',
            clients: 'Veuillez rajouter une action pour ce client',
        },
    },

    expiredPage: {
        title: "Le lien auquel vous essayez d'accéder a expiré",
    },

    agencyPortal: {
        openConfirm: 'Etes-vous certain de vouloir ouvrir la barrière ?',
    },
}
