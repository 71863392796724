import axios, { AxiosResponse } from 'axios'
import i18n from 'i18n/config'
export const API_URL = process.env.REACT_APP_API_URL + '/api'

export const notSecuredHttpGet = <T>(path: string, transformResponse?: (data: any) => void): Promise<T> =>
    axios
        .get(`${path}`, {
            baseURL: API_URL,
            transformResponse,
        })
        .then((res) => res.data)
        .catch((err) => err)

export const notSecuredHttpPost = (
    path: string,
    data: unknown,
    transformResponse?: (data: any) => void,
): Promise<AxiosResponse> =>
    axios
        .post(`${path}`, data, {
            baseURL: API_URL,
            headers: {
                'Accept-Language': i18n.language,
                'X-Timezone-Id': new Date().getTimezoneOffset(),
            },
            transformResponse,
        })
        .then((res) => res)
        .catch((err) => err)

export const securedHttpGet = <T>(
    path: string,
    params?: { [key: string]: any },
    transformResponse?: (data: any) => void,
): Promise<T> => {
    return axios
        .get(`${path}`, {
            baseURL: API_URL,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Accept-Language': i18n.language,
                'X-Timezone-Id': Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            params,
            transformResponse,
        })
        .then((res) => res.data)
        .catch((err) => err)
}

export const securedHttpPost = (
    path: string,
    data: unknown,
    transformResponse?: (data: any) => void,
    params?: any,
): Promise<AxiosResponse> =>
    axios
        .post(`${path}`, data, {
            baseURL: API_URL,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                'Accept-Language': i18n.language,
                'X-Timezone-Id': Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            transformResponse,
            params,
        })
        .then((res) => res)
        .catch((err) => err)
