import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Icon, renderIcon } from 'helpers/icons'
import { getAgencyPortalAvailability, openAgencyPortal } from 'api/agency'
import { checkOperationExpiry, getUserGarage } from 'api/user'
import { userExecPickup, userStartPickup } from 'api/user'
import { ExchangeData } from 'types/exchange'
import { UserStaticPages } from 'types/user'
import { displayError, displayNotif } from 'helpers/displayNotif'
import { convertUTCDateToLocalDate, renderFullDate, renderTime } from 'helpers/time'

import Loader from 'components/Loader'
import HeaderUI from 'components/Header'

import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Modal from 'antd/lib/modal'
import jwt_decode from 'jwt-decode'
import ExpiredPage from 'components/ExpiredPage'

import { ExclamationCircleFilled } from '@ant-design/icons'

const { confirm } = Modal

const UserPickupPage: FunctionComponent = () => {
    const { t, i18n } = useTranslation()
    const { lang, token } = useParams<UserStaticPages>()
    let formattedToken = token
    const [operationExpiry, setOperationExpiry] = useState(false)
    const [hasPortal, setPortal] = useState(false)
    const [portalReq, setPortalReq] = useState({
        loading: false,
        success: false,
    })
    const [execReq, setExecReq] = useState({
        loading: false,
        success: false,
    })
    const [exchange, setExchange] = useState<ExchangeData | null>(null)
    formattedToken = token.replace(/€/g, '.')

    const decodedJson = jwt_decode(formattedToken) as any
    const guid = decodedJson.OperationId
    const tenantId = decodedJson.TenantId
    const agencyId = decodedJson.AgencyId
    let expiredOn = decodedJson.exp as Date
    console.log('>>> expiredOn', expiredOn)
    console.log('>>> date now', Date.now() / 1000)
    expiredOn = convertUTCDateToLocalDate(expiredOn)
    console.log('>>> expiredOn converted', expiredOn.getTime())
    const expirationLimit = expiredOn.getTime() < Date.now() / 1000
    console.log('>>> expirationLimit', expirationLimit)
    useEffect(() => {
        const getOperationExpiry = async () => {
            const reqOperationExpiry = await checkOperationExpiry(tenantId, agencyId, guid)
            setOperationExpiry(reqOperationExpiry.data)

            // If operation not expired, fetch others data
            if (!reqOperationExpiry.data && !expirationLimit) {
                getPortalStatus()
                fetchGarage()
            }
        }
        const getPortalStatus = async () => {
            const portal = await getAgencyPortalAvailability(tenantId, agencyId)
            setPortal(portal.data)
        }
        const fetchGarage = async () => {
            const garage = await getUserGarage(guid, tenantId, agencyId)
            const data = garage.data
            setExchange(data)

            userStartPickup({
                OperationId: guid,
                TenantId: data.TenantId,
                AgencyId: data.AgencyId,
                PickUpUser: {
                    Id: data.PickUpUser.Id,
                    Name: data.PickUpUser.Name,
                    EmailAddress: data.PickUpUser.EmailAddress,
                    PreferedLanguage: data.PickUpUser.PreferedLanguage,
                },
            })
        }

        getOperationExpiry()
        i18n.changeLanguage(lang)
    }, [guid])

    const executePickUp = async () => {
        if (exchange) {
            setExecReq((prevState) => ({
                ...prevState,
                loading: true,
            }))
            const pickup = await userExecPickup({
                OperationId: guid,
                TenantId: exchange.TenantId,
                AgencyId: exchange.AgencyId,
                PickUpUser: {
                    Id: exchange.PickUpUser.Id,
                    Name: exchange.PickUpUser.Name,
                    EmailAddress: exchange.PickUpUser.EmailAddress,
                    PreferedLanguage: exchange.PickUpUser.PreferedLanguage,
                },
            })

            setExecReq({
                success: pickup.status === 200,
                loading: false,
            })
            displayNotif(t, pickup.status, { key: 'feedback.lockerOpened' })
        }
    }

    const openPortal = async () => {
        confirm({
            title: t('common.openPortal'),
            icon: <ExclamationCircleFilled />,
            content: t('agencyPortal.openConfirm'),
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk: async () => {
                setPortalReq((prevState) => ({
                    ...prevState,
                    loading: true,
                }))
                const openPortalReq = await openAgencyPortal(tenantId, agencyId, guid)

                openPortalReq instanceof Error && displayError(t, 'feedback.error')

                setPortalReq({
                    success: openPortalReq.status === 200,
                    loading: false,
                })
            },
        })
    }

    if (expirationLimit || operationExpiry) return <ExpiredPage />
    if (!exchange && !operationExpiry) return <Loader />
    return (
        <Fragment>
            <HeaderUI onlyLogo />
            <section className="static-hero container bg-gray-50">
                {/* <Text className="c-primary fw-600 mb-3">{t('pickUpPage.hero.subHeading')}</Text> */}
                <Title className="fw-600">
                    {t('common.hello')} {exchange?.PickUpUser.Name}
                </Title>
                <Text type="secondary" className="mt-4">
                    {t('pickUpPage.hero.description')}
                </Text>
                {hasPortal && (
                    <div>
                        <Text type="secondary" className="mt-8 mb-4">
                            {t('dropOffPage.hero.openPortal')}
                        </Text>
                        <Button
                            type="primary"
                            size="large"
                            onClick={openPortal}
                            className={`${portalReq.success ? 'btn-success' : ''}`}
                            loading={portalReq.loading}
                            disabled={portalReq.success}
                        >
                            {portalReq.success ? t('common.portalIsOpened') : t('common.openPortal')}
                            {renderIcon({ name: portalReq.success ? 'checkCircle' : 'unlock', position: 'right' })}
                        </Button>
                    </div>
                )}
                {(!hasPortal || (hasPortal && portalReq.success)) && (
                    <Button
                        type="primary"
                        onClick={executePickUp}
                        className={`mt-6 ${execReq.success ? 'btn-success' : ''}`}
                        loading={execReq.loading}
                        disabled={execReq.success}
                        size="large"
                    >
                        {execReq.success ? t('common.lockerIsOpened') : t('common.openLocker')}
                        {renderIcon({ name: execReq.success ? 'checkCircle' : 'unlock', position: 'right' })}
                    </Button>
                )}
            </section>
            <section className="static-section container">
                <Icon name="alert" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('pickUpPage.section1.subHeading')}</Text>
                <Title level={2} className="title-md fw-600">
                    {t('pickUpPage.section1.title')}
                </Title>
                <Text type="secondary" className="mt-6 mb-4">
                    {t('pickUpPage.section1.text.1')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('pickUpPage.section1.text.2')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('pickUpPage.section1.text.3')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('pickUpPage.section1.text.4')}
                </Text>
                <Text type="secondary" className="mb-6">
                    {t('pickUpPage.section1.text.5')}
                </Text>
                <Text type="secondary">{t('pickUpPage.section1.text.6')}</Text>
                <Divider />
            </section>
            <section className="static-section container">
                <Icon name="calendar" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('pickUpPage.section2.subHeading')}</Text>
                <Title level={2} className="title-md fw-600">
                    {t('pickUpPage.section2.title')}
                </Title>
                {exchange?.DropOffDate && (
                    <Text type="secondary" className="my-10">
                        {renderFullDate(exchange.DropOffDate)} &agrave; {renderTime(exchange.DropOffDate)}
                    </Text>
                )}
                <div>
                    <Text strong className="mb-2">
                        {t('pickUpPage.section2.text.1')}
                    </Text>
                    <Text type="secondary">{t('pickUpPage.section2.text.2')}</Text>
                </div>
                <Divider />
            </section>
            <section className="static-section container">
                <Icon name="pin" circleType="primary" />
                <Text className="c-primary fw-600 mt-6 mb-3">{t('pickUpPage.section3.subHeading')}</Text>
                <Title level={2} className="title-md fw-600">
                    {t('pickUpPage.section3.title')}
                </Title>
                <div className="my-10">
                    <Text type="secondary" className="mb-4">
                        ByMyCar
                    </Text>
                    <Text type="secondary">{t('pickUpPage.section3.text.1')}</Text>
                </div>
                <div className="static-phone">
                    <Button
                        type="primary"
                        icon={renderIcon({ name: 'phone' })}
                        onClick={() => window.open(`tel:${t('pickUpPage.section3.phone.phoneNumber')}`)}
                    />
                    <Text>{t('pickUpPage.section3.phone.title')}</Text>
                    <p className="mt-2">{t('pickUpPage.section3.phone.schedules')}</p>
                    <a href={`tel:${t('pickUpPage.section3.phone.phoneNumber')}`}>
                        {t('pickUpPage.section3.phone.phoneNumber')}
                    </a>
                </div>
                <Divider />
            </section>
            <section className="static-section container">
                <Text type="secondary" className="mb-4">
                    {t('pickUpPage.footer.text.1')}
                </Text>
                <Text type="secondary" className="mb-4">
                    {t('pickUpPage.footer.text.2')}
                </Text>
                <Text type="secondary">{t('pickUpPage.footer.text.3')}</Text>
            </section>
        </Fragment>
    )
}

export default UserPickupPage
