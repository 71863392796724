export const TRANSLATIONS_EN = {
    common: {
        at: 'at',
        noResults: '0 results found',
        back: 'Back',
        modifyInfo: 'Modify',
        delete: 'Delete',
        cancel: 'Cancel',
        save: 'Save',
        confirm: 'Confirm',
        now: 'Now',
        resendEmail: 'Resend the explanatory email',
        retrieveKey: 'Retrieve the key',
        cancelAndKey: 'Cancel and retrieve key',
        downloadInvoice: 'Download the invoice',
        previous: 'Previous',
        next: 'Next',
        validate: 'Validate',
        hello: 'Hello',
        openLocker: 'Open locker',
        lockerIsOpened: 'The locker is open',
        openPortal: 'Open parking barrier',
        portalIsOpened: 'The barrier is open',
        unavailable: 'Unavailable',
        noReturn: 'No return',
        noDeparture: 'No departure',
        all: 'All',
        home: 'Home',
        noObjects: 'No object',
        generalInformations: 'General information',
        details: 'Details',
        logout: 'Logout',
        vehicle: 'Vehicle',
        dropOffKey: 'Drop off the key',
        registration: 'Registration',
        search: 'Search',
        seeMore: 'See more',
        summary: 'Summary',
        notSpecified: 'Not specified',
        add: 'Add',
        yes: 'Yes',
        no: 'No',
    },

    header: {
        exchanges: 'Exchanges',
        reservations: 'Reservations',
        vehicles: 'Vehicles',
        clients: 'Clients',
    },

    language: {
        french: 'French',
        english: 'English',
        italian: 'Italian',
    },

    informations: {
        clientID: 'Client ID',
        Lastname: 'Last Name',
        Firstname: 'First name',
        Address: 'Address',
        PhoneNumber: 'Phone number',
        Email: 'Email',
        brand: 'Brand',
        model: 'Model',
        registrationNumber: 'Registration Number',
        vehicleId: 'Vehicle ID',
        reservationId: 'Reservation ID',
        status: 'Status',
        startDate: 'Start date',
        endDate: 'Return date',
        totalReservations: 'Total reservations',
        waitingVehicleAssociation: 'Waiting for key deposit',
        waitingVehiclePickup: 'Waiting for return',
        waitingVehicleDropOff: 'Waiting for departure',
        closed: 'Finished',
        canceled: 'Canceled',
        companyID: 'Company ID',
        companyName: 'Company Name',
        totalAgency: 'Number of agencies',
        totalEmployees: 'Number of employees',
        garageID: 'Garage ID',
        createdAt: 'Created on',
        job: 'Job',
        registrationDate: 'Registration Date',
        lastModified: 'Last modified on',
        roles: 'Roles',
    },

    feedback: {
        confirmAction: 'Your request has been taken into account.',
        openLocker: 'You are about to open a locker. Would you like to continue ?',
        planDropOff: 'The drop-off appointment has been created.',
        planReturn: 'Your request has been created.',
        lockerOpened: 'The locker is opened.',
        redirectPayment: 'You are being redirected to the payment page...',
        objectCreated: 'The object has been created.',
        error: 'An error occured. Please try again later.',
        clientExist: 'This email adresse already exist.',
        locker: {
            open: 'Are you sure you want to open this locker ?',
            description: 'You are about to open this locker, this action is irreversible',
        },
        cancelDropOff: {
            title: 'Are you sure you want to cancel this drop-off?',
            description: 'You are about to cancel this drop-off, this action is irreversible',
        },
        cancelRetrieve: {
            title: 'Are you sure you want to cancel this retrieve?',
            description: 'You are about to cancel this retrieve, this action is irreversible',
        },
        cancelReservation: {
            title: 'Are you sure you want to cancel this reservation?',
            description: 'You are about to cancel this reservation, this action is irreversible',
        },
        client: {
            modified: 'The client has been modified.',
            created: 'The client has been created.',
            deleted: 'The user {{ name }} has been successfully deleted',
            confirmDelete: 'Confirm deletion of {{name}} ?',
        },
        vehicle: {
            created: 'The vehicle has been created.',
            deleted: 'The vehicle {{name}} has been deleted.',
            confirmDelete: 'Are you sure to delete the vehicle {{name}} ?',
            descriptionDelete: 'You are about to delete this vehicle, this action is irreversible',
            pickUp: "You are about to pickup the vehicle's key. Do you confirm ?",
        },
        agency: {
            created: 'The agency has been created.',
            deleted: 'The agency {{ name }} has been deleted.',
            confirmDelete: 'Are you sure to delete the agency {{name}} ?',
            descriptionDelete: 'You are about to delete this agency, this action is irreversible',
            modified: 'The agency {{ name }} has been modified.',
        },
        employee: {
            created: 'The employee has been created.',
            deleted: 'The employee {{ name }} has been deleted.',
            confirmDelete: 'Are you sure to delete the employee {{name}} ?',
            descriptionDelete: 'You are about to delete this employee, this action is irreversible',
            modified: 'The employee {{ name }} has been modified.',
        },
    },

    error: {
        email: 'Please enter a valid email',
        password: 'Please enter your password',
        phone: 'Please enter a valid phone number',
    },

    form: {
        label: {
            password: 'Password',
            Firstname: 'First name',
            Lastname: 'Last name',
            Name: 'Name',
            Email: 'Email',
            PhoneNumber: 'Phone number',
            Address: 'Address',
            PersonalItemName: 'Object',
            fullName: 'Full name or email',
            selectObject: 'History of objects',
            agency: 'Agency',
            locker: 'Locker',
            dropOff: 'Date of Dropoff',
            pickUp: 'Date of Pickup',
            registration: 'Immatriculation',
            brand: 'Brand',
            model: 'Model',
            rentalId: 'Reservation Number',
            fullAddress: 'Complete Address',
            Post: 'Job',
            searchVehicle: 'Search a vehicle',
            availableVehicles: 'Available',
            PreferedLanguage: 'Preferred Language',
            Roles: 'Roles',
        },
        placeholder: {
            email: 'Enter your email',
            password: 'Enter your password',
            confirmPassword: 'Confirm your password',
            search: 'Search for a client',
            object: 'Name of object',
            selectObject: 'Select an object',
            Firstname: "Enter the client's first name",
            Lastname: 'Enter his lastname',
            Email: 'Enter his email',
            PhoneNumber: 'Enter his phone number',
            Address: 'Enter his address',
            PersonalItemName: 'Name of object',
            dropOffDate: 'Select a drop-off date',
            pickUpDate: 'Select a pick up date',
            price: '1,000.00',
            fullName: "Enter the customer's full name or email",
            agency: 'Select an agency',
            locker: 'Select a locker',
            dropOff: 'Enter a date of dropoff',
            registration: "Enter the'registration of the number",
            brand: 'Enter the brand of the vehicle',
            model: 'Enter the model of the vehicle',
            rentalId: 'Enter the number of the reservation',
            agencyName: 'Enter the name of the agency',
            fullAddress: 'Enter your complete address',
            Post: 'Enter the job',
            searchVehicle: 'Name of the vehicle',
            Roles: 'Select one or more roles',
        },
        forgetPassword: 'Forgot your password ?',
        skipStep: 'Skip this step',
        messages: {
            required: 'This field must be filled.',
            pattern: {
                mismatch: 'This field is not valid.',
            },
        },
    },

    login: {
        title: 'Welcome',
        subtitle: 'Login to your account',
        submit: 'Login',
        noAccount: "Still don't have a account ?",
        registerLink: 'Register',
    },

    register: {
        title: 'Create a account',
        submit: 'Register',
        loginText: 'Already have an account ?',
        loginLink: 'Login',
    },

    resetPassword: {
        title: 'Reset password',
        subtitle: 'Enter your email linked to your account and we will send youa link to reset your password',
        submit: 'Submit',
        loginLink: 'Go back to login page',
    },

    clientPage: {
        timeline: 'Timeline',
        client: 'Client info',
        reservations: 'Reservations',
        exchanges: 'Exchanges',
        invoices: 'Invoices',
        object: 'Object',
        addAction: 'Add an action',
        delete: 'Delete a client',
        descriptionDelete: 'You are about to delete a client, warning this action est irreversible',
        historyReservations: 'History of reservations',
    },

    reservationPage: {
        timeline: 'Timeline',
        vehicle: 'Vehicle',
        clientLink: 'See the client',
        vehicleLink: 'See the vehicle',
    },

    vehiclePage: {
        timeline: 'Timeline',
        vehicle: 'Vehicle Info',
        reservation: 'Reservation',
        damages: 'Damages',
        delete: 'Delete a vehicle',
    },

    clientModal: {
        title: 'Add a new client',
        description: 'Please fill out the client info and save.',
    },

    objectModal: {
        title: 'Rattach an object to the client (optional)',
        description: 'Please fill in an object which will be attached to a client',
        selectTitle: 'Enter the object for this exchange',
        selectDescription: 'Please select or add the object for this demand',
        selectPlaceholder: 'Car keys',
        addObject: 'Add a new object',
        backHistory: 'Return to history of objects',
    },

    vehiclesModal: {
        title: '(1/3) Assign a vehicle',
        description: 'Please enter the registration, brand or model of the vehicle you are looking for',
    },

    createVehicleModal: {
        title: 'Create a vehicle',
    },

    selectClientModal: {
        title: '(2/3) Assign the reservation',
        description: 'Please enter the name or email of the client that will be assigned to this reservation',
        newClient: 'Add a new client',
        existingClient: 'Existing client',
    },

    reservationsDateModal: {
        title: '(3/3) Choose your dates',
        description: 'Please enter a date of departure and/or a date of return',
        pickUpDate: 'Departure',
        pickUpPlaceholder: 'Choose a date of departure',
        dropOffDate: 'Return',
        dropOffPlaceholder: 'Choose a date of return',
    },

    dropOffModal: {
        title: 'Locker / Date of deposit',
        description:
            'Please select the locker in which the object will be deposited in and also the date planned for the deposit',
    },

    invoiceModal: {
        title: 'Import invoice',
        description: 'Please import the invoice so that we can send it to the client',
        import: 'Click to import',
        drag: 'or drag-and-drop',
        restriction: 'PDF (max. 800x400px)',
    },

    agencyModal: {
        title: 'Add an agency',
        description: 'Please fill in the informations of the agency',
        modifyInfo: 'Modify an agency',
    },

    employeeModal: {
        title: 'Add an employee',
        description: 'Please fill in the informations of the employee',
        modifyInfo: 'Modify an employee',
    },

    events: {
        userDropOffStep: 'Waiting for the deposit by {{name}} the {{date}}...',
        userDropOffFinishedStep: 'Dropped by {{name}}',
        garagePickUpStep: 'In waiting to be retrieved by the garage...',
        pickUpFinishedStep: 'Retrieved by {{name}}',
        garageDropOffStep: 'In waiting of the deposit by the garage...',
        garageDropOffFinishedStep: 'Dropped by {{name}}',
        garageDropOffCanceledStep: 'Canceled by {{name}}',
        userDropOffPlanedStep: 'Planifid by {{name}}',
        pickUpCanceledStep: 'Canceled by {{name}}',
        paidGarageReturnItemPlanedStep: 'Planifid by {{name}}',
        freeGarageReturnItemPlanedStep: 'Planifid by {{name}}',
        userPaymentStep: 'In waiting for the payment...',
        userPaymentDoneStep: 'Payment made !',
        userPickUpStep: 'In waiting to be retrieved by the client...',
        vehicle: {
            pickUpVehicleAppointmentMadeStep: 'Scheduled departure for the reservation n°{{id}} of {{name}}',
            appointmentVehicleAssociationStep: 'Awaiting to drop off the vehicle key by an agent...',
            appointmentVehicleAssociatedStep: 'Vehicle key dropped off',
            appointmentVehiclePickUpStep: 'Awaiting departure from {{name}}...',
            appointmentVehiclePickedUpStep: 'Departure made',
            dropOffVehicleAppointmentMadeStep: 'Schedule return for the reservation n°{{id}} of {{name}}',
            appointmentVehiclePickedUpStepCanceled: 'Canceled departure for ther reservation n°{{id}} of {{name}}',
            appointmentVehicleDroppedOffStepCanceled: 'Canceled return for the reservation n°{{id}} of {{name}}',
            appointmentVehicleDropOffStep: 'Awaiting return from {{name}}...',
            appointmentVehicleDroppedOffStep: 'Return made by {{name}}',
        },
    },

    newAction: {
        creation: {
            title: 'List of objects',
        },
        list: {
            title: 'Tell us more about the action',
            description: 'Please select the corresponding object',
            button: 'Add a new object',
        },
        type: {
            title: 'Tell us more about the action',
            description: 'Please select the corresponding action from the choices below',
            dropOff: 'Take a drop-off appointment',
            planFree: 'Return key without payment',
            planPaid: 'Return key with payment',
            reservation: 'Create a reservation',
        },
        date: {
            title: 'Indicate the date and place of drop-off',
            inDoor: 'Indoor',
            outDoor: 'Outdoor',
        },
        invoice: {
            pickUp: 'Indicate the date and place of retrieval',
            button: 'Import invoice',
            description: 'Please import the invoice, so that we can send it to the client',
            price: 'Amount of the invoice',
        },
    },

    404: {
        title: 'ERROR 404',
        subtitle: 'Sorry, this page is not available',
        text: 'It seems you got here by accident ?',
    },

    dropOffPage: {
        hero: {
            subHeading: 'Nice to see you !',
            description:
                'Our ByMyCar After-Sales Service is equipped with a digital and secure key advisor so you no longer have to wait for an advisor to be available to deposit your vehicle key.',
            openPortal: 'To open the parking barrier and access the car park, please click on the following button',
        },
        section1: {
            subHeading: 'Very important',
            title: 'Some instructions',
            text: {
                1: '1. Go to the digital key locker located near the reception.',
                2: '2. Then click on the button below.',
                3: '3. Press the key locker pushbutton when it starts flashing.',
                4: '4. Put the key of your vehicle in the released locker and close it.',
                5: 'Our teams will be informed in real time of the arrival of your vehicle.',
            },
        },
        section2: {
            subHeading: "Don' forget",
            title: 'To be deposited before',
            text: {
                1: 'Available times',
                2: 'Monday to Saturday : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Yes but where?',
            title: 'Place of deposit',
            text: {
                1: '3 Rue Bannier, 45000 Orléans',
            },
            phone: {
                title: 'Phone number',
                schedules: 'Monday to Saturday : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'We will keep you informed by email as soon as we receive your vehicle.',
                2: 'Excellent day.',
                3: 'Your partner ByMyCar',
            },
        },
    },

    rentalDropOffPage: {
        hero: {
            subHeading: 'Nice to see you !',
            description:
                'Our agency is equipped with a digital and secure key locker so that you no longer have to wait for a consultant to be available to return the key of your rental vehicle.',
            openPortal: 'To open the parking barrier and access the car park, please click on the following button',
        },
        section1: {
            subHeading: 'Very important',
            title: 'Some instructions',
            text: {
                1: '1. Go to the digital key locker located near the reception.',
                2: '2. Then click on the button below.',
                3: '3. Press the key locker pushbutton when it starts flashing.',
                4: '4. Place your rental car key in the released locker and close it.',
                5: 'Our teams will be informed in real time of the return of your rental vehicle.',
            },
        },
        section2: {
            subHeading: "Don' forget",
            title: 'To be deposited before',
            text: {
                1: 'Available times',
                2: 'Monday to Saturday : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Yes but where?',
            title: 'Place of deposit',
            text: {
                1: '3 Rue Bannier, 45000 Orléans',
            },
            phone: {
                title: 'Phone number',
                schedules: 'Monday to Saturday : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'We will keep you informed by email as soon as we receive your vehicle.',
                2: 'Excellent day.',
                3: 'Your partner ByMyCar',
            },
        },
    },

    paymentFailed: {
        title: 'Payment failed !',
        text: 'We invite you to repeat the operation using another method of payment. <br /> <br /> You can close this window.',
    },

    paymentSuccess: {
        subHeading: 'Congratulations',
        title: 'Payment successful !',
        text: {
            1: 'You will receive in a few minutes, by email, the instructions to retrieve your vehicle. <br /> <br /> You can close this window.',
            2: 'We thank you for your trust !<br /> <br /> Your partner ByMyCar',
        },
        button: 'Back to home',
    },

    paymentPage: {
        subHeading: 'Waiting for payment',
        text: ' Your vehicle is ready !<br /> <br /> To save time during the pickup, we invite you to pay the amount of your invoice directly throught on secured service.',
        button: 'Proceed to payment',
    },

    pickUpPage: {
        hero: {
            subHeading: 'Nice to see you !',
            description:
                'Our ByMyCar After-Sales Service is equipped with a digital and secure key advisor so you no longer have to wait for an advisor to be available to deposit your vehicle key.',
            openPortal: 'To open the parking barrier and access the car park, please click on the following button',
        },
        section1: {
            subHeading: 'Very important',
            title: 'Some instructions',
            text: {
                1: '1. Go to the digital key locker located near the reception.',
                2: '2. Then click on the button below.',
                3: '3. Press the key locker button when it starts to flash.',
                4: '4. Put the key of your vehicle in the released locker and close it.',
                5: '5. Collect your vehicle directly from the car park.',
                6: 'Our teams will be informed in real time of the arrival of your vehicle.',
            },
        },
        section2: {
            subHeading: "Don't forget",
            title: 'To be deposited before',
            text: {
                1: 'Available times',
                2: 'Monday to Saturday : 08:00 - 19:00',
            },
        },
        section3: {
            subHeading: 'Yes but where?',
            title: 'Place of deposit',
            text: {
                1: '3 Rue Bannier, 45000 Orléans',
            },
            phone: {
                title: 'Phone number',
                schedules: 'Monday to Saturday : 8:00 - 23:00',
                phoneNumber: '+33 6 23 93 02 93',
            },
        },
        footer: {
            text: {
                1: 'We will keep you informed by email as soon as the intervention on your vehicle is finished.',
                2: 'Excellent day.',
                3: 'Your partner ByMyCar',
            },
        },
    },

    rentalPickUpPage: {
        hero: {
            subHeading: 'Nice to see you !',
            description: 'To retrieve your rental car key, go to the digital key locker and click on this button.',
            openPortal: 'To open the parking barrier and access the car park, please click on the following button',
        },
        section1: {
            subHeading: 'Very important',
            title: 'Some instructions',
            text: {
                1: '1. Go to the digital key locker located near the reception.',
                2: '2. Then click on the button above.',
                3: '3. Press the key locker button when it starts to flash.',
                4: '4. Retrieve your vehicle key from the released locker and close it.',
                5: '5. Pick up the vehicle directly from the parking lot.',
                6: 'Our teams will be informed in real time of your departure.',
            },
        },
        section2: {
            subHeading: "Don't forget",
            title: 'Start date',
            text: {
                1: 'Available times',
                2: 'Monday to Sunday : 00:00 - 23:59',
            },
        },
        section3: {
            subHeading: 'Yes but where ?',
            title: 'Pick-up location',
            company: 'Avis Location Voiture - Toulon',
            text: {
                1: 'Gare de Toulon',
                2: 'Gare Sncf Place De L Europe Retours Parking Effia -1, 83000 Toulon',
            },
            phone: {
                title: 'Phone number',
                schedules: 'Monday to Sunday : 00:00 - 23:59',
                phoneNumber: '0 820 61 16 45',
            },
        },
        footer: {
            text: {
                1: 'Have a great day.',
                2: 'Avis Location Voiture - Toulon',
            },
        },
    },

    parametersPage: {
        title: 'Parameters',
        subtitle: 'Manage all your parameters',
        addLocker: 'Add a locker',
        tabs: {
            company: 'Company',
            agency: 'Agencies',
            locker: 'Locker',
            stripe: 'Stripe',
            interface: 'Interface',
        },
        stripe: {
            title: 'Stripe',
            subtitle: 'Manage all your interactions with your Stripe account',
        },
        interface: {
            title: 'My interface',
            subtitle: "Customize your interface to your company's image",
            logo: 'Logo',
            primaryColor: 'Primary color',
        },
    },

    agencyPage: {
        addEmployee: 'Add an employee',
        delete: 'Delete agency',
        addAgency: 'Add an agency',
    },

    employeePage: {
        delete: 'Delete employee',
    },

    listVehicles: {
        title: 'Vehicles',
        subtitle: 'Manage all your vehicles',
        add: 'Add a vehicle',
        deleteVehicle: 'Delete vehicle',
    },

    listExchanges: {
        title: 'Exchanges',
        subtitle: 'Manage all your exchanges',
        add: 'Add an exchange',
    },

    listClients: {
        title: 'Clients',
        subtitle: 'Manage all your clients',
        add: 'Add a client',
    },

    listReservations: {
        title: 'Reservations',
        subtitle: 'Manage all your reservations',
        add: 'Add a reservation',
        clientLink: 'See the client',
        vehicleLink: 'See the vehicle',
    },

    gridExchange: {
        searchPlaceholder: 'Search for an exchange',
        columns: {
            dropOffUser: 'Dropped by',
            dropOffDate: 'Date of deposit',
            pickUpUser: 'For',
            pickUpDate: 'Date of recovery',
            depositDate: 'Date of deposit',
            status: 'Status',
        },
        tabs: {
            waiting: 'In waiting of deposit',
            delivered: 'Delivered',
            retrieved: 'Retrieved',
            canceled: 'Canceled',
        },
        tags: {
            waiting: 'In waiting of deposit',
            delivered: 'Delivered',
            retrieved: 'Retrieved',
            canceled: 'Canceled',
        },
    },

    gridReservations: {
        searchPlaceholder: 'Search for a reservation',
        columns: {
            reservation: 'Reservation',
            client: 'Client',
            registrationNumber: 'Registration Number',
            brandModel: 'Brand/Model',
            startDate: 'Start date',
            returnDate: 'Return date',
            status: 'Status',
        },
        tabs: {
            waitingAssociation: 'In waiting of deposit',
            waitingPickup: 'In waiting of departure',
            waitingDropOff: 'In waiting of return',
            closed: 'Finished',
            canceled: 'Canceled',
        },
        tags: {
            waitingVehicleAssociation: 'In waiting of key deposit',
            waitingVehiclePickup: 'In waiting of departure',
            waitingVehicleDropOff: 'In waiting of return',
            closed: 'Finished',
            canceled: 'Canceled',
        },
    },

    gridVehicles: {
        columns: {
            id: 'ID',
            registrationNumber: 'Registration Number',
            brandModel: 'Brand/Model',
            status: 'Status',
        },
        tabs: {
            available: 'Available',
            booked: 'Booked',
            unavailable: 'Unavailable',
        },
        tags: {
            available: 'Available',
            booked: 'Booked',
            unavailable: 'Unavailable',
        },
    },

    gridClients: {
        columns: {
            fullName: 'Full name',
            email: 'Email',
            phone: 'Phone number',
            id: 'ID',
            registrationNumber: 'Registration Number',
            startDate: 'Start date',
            returnDate: 'Return date',
            status: 'Status',
        },
    },

    gridAgencies: {
        title: 'Agencies',
        summary: 'Summary agencies',
        columns: {
            name: 'Name',
            address: 'Address',
            phoneNumber: 'Phone Number',
        },
    },

    gridEmployees: {
        title: 'Employees',
        summary: 'Summary',
        columns: {
            name: 'Full name',
            job: 'Job',
            email: 'Email',
            phoneNumber: 'Phone Number',
        },
        tabs: {
            employee: 'Agency employees',
        },
    },

    gridLocker: {
        title: 'Locker',
        columns: {
            name: 'Name',
            agency: 'Agency',
            address: 'Address',
            openingHours: 'Opening hours',
        },
    },

    timeline: {
        title: 'Timeline',
        noActions: {
            vehicles: 'Please add an action for this timeline',
            clients: 'Please add an action for this client',
        },
    },

    expiredPage: {
        title: 'The link you attempted to access has expired',
    },

    agencyPortal: {
        openConfirm: 'Are you sure that you want to open the barrier?',
    },
}
